import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { communityOwnerBoardController } from '@/modules/common/components/section/owner-section-controller'
import { SummaryInfo } from '@/modules/referral/viemmodel/referral-viewmodel'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { Subject } from 'rxjs'

export class RewardViewModel {
  @observable earningActions: any[] = []
  @observable filter = { page: 1, itemPerPage: 10 }
  @observable totalHistoryPage = 0
  @observable chartFilter: '7d' | '30d' = '7d'
  @observable chartLoading = false
  @observable chartData = []
  @observable summaryInformation?: SummaryInfo = undefined
  @observable referralRewardActions: any[] = []

  private _disposers: IReactionDisposer[]
  private _unsubcrible = new Subject()

  constructor() {
    this.fetchData()

    this._disposers = [
      reaction(
        () => this.filter,
        (filter) => {
          if (filter) {
            this.getRewardHistory(filter)
          }
        }
      ),
      reaction(
        () => this.chartFilter,
        (filter) => {
          if (filter) {
            this.getChartData(filter)
          }
        }
      ),
    ]
  }

  @asyncAction *fetchSummaryInfo() {
    try {
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getRecentReferralSummaryInfo(id)
        this.summaryInformation = res
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchReferralRewardActions() {
    try {
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getReferralRewardActions(id)
        this.referralRewardActions = res
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }
  
  @asyncAction *fetchData() {
    try {
      loadingController.increaseRequest()
      const promises = [
        this.fetchSummaryInfo(),
        this.fetchReferralRewardActions(),
        this.getRewardHistory(this.filter),
        this.getChartData(this.chartFilter),
      ]
      yield Promise.all(promises)
    } catch (error) {
      snackController.commonError(error)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @asyncAction *getRewardHistory(filter) {
    try {
      const res = yield apiService.earningActions.getEarningActions(filter)
      console.log("=======resdddd", res)
      if (res) {
        this.earningActions = res.data
        this.totalHistoryPage = res.meta.totalPage
      }
    } catch (error) {}
  }

  @asyncAction *getChartData(filter) {
    try {
      this.chartLoading = true
      const data = yield apiService.earningActions.getChartData(filter)
      this.chartData = data
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.chartLoading = false
    }
  }

  @action.bound changeFilter(filter) {
    this.filter = { ...filter }
  }

  @action.bound changeChartFilter(filter) {
    this.chartFilter = filter
  }

  @action.bound nextPage() {
    this.filter = { ...this.filter, page: this.filter.page + 1 }
  }

  @action.bound prevPage() {
    this.filter = { ...this.filter, page: this.filter.page - 1 }
  }

  @observable getMoreDialog = false

  @action.bound openGetMoreRewardDialog(val: boolean) {
    this.getMoreDialog = val
  }

  @computed get categories() {
    const currentTime = moment()
    let startTime
    const categories: string[] = []
    if (this.chartFilter === '7d') {
      startTime = currentTime.subtract(7, 'day')
      for (let i = 0; i < 7; i++) {
        const time: any = startTime.add(1, 'day').format('DD-MM-YYYY')
        categories.push(time)
      }
    } else if (this.chartFilter === '30d') {
      startTime = currentTime.subtract(30, 'day')
      for (let i = 0; i < 30; i++) {
        const time: any = startTime.add(1, 'day').format('DD-MM-YYYY')
        categories.push(time)
      }
    }
    return categories
  }

  @computed get postingSeries() {
    const currentTime = moment()
    let startTime
    const postingSeries: number[] = []
    if (this.chartFilter === '7d') {
      startTime = currentTime.subtract(7, 'day')
      for (let i = 0; i < 7; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'post'
        )
        if (data && data?.count) {
          postingSeries.push(data.count)
        } else {
          postingSeries.push(0)
        }
      }
    } else if (this.chartFilter === '30d') {
      startTime = currentTime.subtract(30, 'day')
      for (let i = 0; i < 30; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'post'
        )
        if (data && data?.count) {
          postingSeries.push(data.count)
        } else {
          postingSeries.push(0)
        }
      }
    }
    return postingSeries
  }

  @computed get commentSeries() {
    const currentTime = moment()
    let startTime
    const commentSeries: number[] = []
    if (this.chartFilter === '7d') {
      startTime = currentTime.subtract(7, 'day')
      for (let i = 0; i < 7; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'comment'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    } else if (this.chartFilter === '30d') {
      startTime = currentTime.subtract(30, 'day')
      for (let i = 0; i < 30; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'comment'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    }

    return commentSeries
  }

  @computed get upvoteSeries() {
    const currentTime = moment()
    let startTime
    const commentSeries: number[] = []
    if (this.chartFilter === '7d') {
      startTime = currentTime.subtract(7, 'day')
      for (let i = 0; i < 7; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'upvote'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    } else if (this.chartFilter === '30d') {
      startTime = currentTime.subtract(30, 'day')
      for (let i = 0; i < 30; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'upvote'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    }
    return commentSeries
  }

  @computed get inviteSeries() {
    const currentTime = moment()
    let startTime
    const commentSeries: number[] = []
    if (this.chartFilter === '7d') {
      startTime = currentTime.subtract(7, 'day')
      for (let i = 0; i < 7; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'referral'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    } else if (this.chartFilter === '30d') {
      startTime = currentTime.subtract(30, 'day')
      for (let i = 0; i < 30; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'referral'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    }
    return commentSeries
  }

  @computed get acceptSeries() {
    const currentTime = moment()
    let startTime
    const commentSeries: number[] = []
    if (this.chartFilter === '7d') {
      startTime = currentTime.subtract(7, 'day')
      for (let i = 0; i < 7; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'referral-accept'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    } else if (this.chartFilter === '30d') {
      startTime = currentTime.subtract(30, 'day')
      for (let i = 0; i < 30; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'referral-accept'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    }
    return commentSeries
  }

  @computed get otherSeries() {
    const currentTime = moment()
    let startTime
    const commentSeries: number[] = []
    if (this.chartFilter === '7d') {
      startTime = currentTime.subtract(7, 'day')
      for (let i = 0; i < 7; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'other'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    } else if (this.chartFilter === '30d') {
      startTime = currentTime.subtract(30, 'day')
      for (let i = 0; i < 30; i++) {
        const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
        const data: any = this.chartData.find(
          (item) => (item as any).date === time && (item as any).actionType === 'other'
        )
        if (data && data?.count) {
          commentSeries.push(data.count)
        } else {
          commentSeries.push(0)
        }
      }
    }
    return commentSeries
  }

  @computed get series() {
    return [
      {
        name: 'Posting',
        data: this.postingSeries,
      },
      {
        name: 'Comment',
        data: this.commentSeries,
      },
      {
        name: 'Like',
        data: this.upvoteSeries,
      },
      {
        name: 'Invite',
        data: this.inviteSeries,
      },
      {
        name: 'Accept Invitation',
        data: this.acceptSeries,
      },
      {
        name: 'Other',
        data: this.otherSeries,
      },
    ]
  }


  @computed get yourReferralLink() {
    const host = process.env.VUE_APP_HOST
    const profileId = walletStore.userProfile?._id
    return `${host}?ref=${profileId}`
  }

  @computed get options() {
    return {
      chart: {
        background: '#ffffff00',
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        markers: {
          radius: 0,
        },
      },
      colors: ['#D6F76B', '#FF426F', '#5947F5', '#8BC7FF', '#fafafa', '#34eb89'],
      grid: {
        show: false,
        yaxis: {
          lines: {
            show: true,
            startonly: true 
          },
        },
      },
      xaxis: {
        categories: this.categories,
      },
      theme: {
        mode: 'dark',
      },
    }
  }

  @computed get totalReferralUser() {
    return this.summaryInformation?.referralCount || 0
  }

  @computed get incompleteInvite() {
    return this.summaryInformation?.pendingCount || 0
  }

  @computed get inviteCompleted() {
    return this.summaryInformation?.completedCount || 0
  }

  @computed get rewardBase() {
    return this.summaryInformation?.tier?.baseReward
  }

  @computed get currentTier() {
    return this.summaryInformation?.tier?.tier
  }

  @computed get totalReferralReward() {
    let total = 0
    this.referralRewardActions.forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

}