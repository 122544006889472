



































































import { walletStore } from '@/stores/wallet-store'
import { rewardStore } from '@/stores/rewardStore'
import { Observer } from 'mobx-vue'
import { Component, Inject, Provide, Vue } from 'vue-property-decorator'
import { RewardViewModel } from '../viewmodel/reward-viewmodel'

@Observer
@Component({
  components: {
    'notebook-nft': () => import('@/modules/reward/components/reward/notebook-nft.vue'),
    'pen-nft': () => import('@/modules/reward/components/reward/pen-nft.vue'),
    'community-reward': () => import('@/modules/reward/components/reward/community-reward.vue'),
    'your-referral-link': () => import('@/modules/reward/components/reward/your-referral-link.vue'),
    'history-reward': () => import('@/modules/reward/components/reward/history-reward.vue'),
    'reward-information': () => import('@/modules/reward/components/reward/reward-information.vue'),
    'reward-post': () => import('@/modules/reward/components/reward/reward-post.vue'),
    'get-more-reward': () => import('@/modules/reward/dialog/get-more-reward.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new RewardViewModel()
  rewardStore = rewardStore
  wallet = walletStore
  verify = true
}
